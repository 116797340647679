import React, { useEffect, useRef } from 'react';
import ChatMessage from './ChatMessage';

const ChatBox = ({ messages, onSubmitForm }) => {
  const chatEndRef = useRef(null);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  return (
    <div style={styles.chatBox}>
      {messages.map((message, index) => (
        <ChatMessage key={index} message={message} onSubmitForm={onSubmitForm} />
      ))}
      <div ref={chatEndRef} />
    </div>
  );
};

const styles = {
  chatBox: {
    height: '62vh',
    overflowY: 'scroll',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#f9f9f9',
    position: 'relative',
    margin : "10px",
  },
};

export default ChatBox;
