import React, { useState } from "react";

const DynamicForm = ({ form, onSubmit }) => {
  const [formData, setFormData] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  if (!form || typeof form !== "object") return null;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    setIsSubmitted(true);
  };

  if (isSubmitted) {
    return <p>Envoyé ! </p>;
  }

  const renderField = (fieldKey, field) => {
    switch (field.type) {
      case "text":
        return (
          <div key={fieldKey}>
            <label>{field.description}</label>
            <input type="text" name={fieldKey} onChange={handleChange} />
          </div>
        );
      case "dropdown":
        return (
          <div key={fieldKey}>
            <label>{field.description}</label>
            <select name={fieldKey} onChange={handleChange}>
              {field.values &&
                field.values.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
            </select>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {Object.keys(form).map((fieldKey) =>
        renderField(fieldKey, form[fieldKey])
      )}
      <button type="submit">Envoyer</button>
    </form>
  );
};

export default DynamicForm;
