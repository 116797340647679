import {
  INIT_CONTEXT_IA_SUCCESS,
  INIT_CONTEXT_IA_FAILURE,
  GET_RESPONSE_SUCCESS,
  GET_RESPONSE_FAILURE,
  USER_MESSAGE,
  SET_LOADING,
} from "../actions/types";

const initialState = {
  messages: [],
  loading: false,
  userSession: null,
  error: null,
};

const iaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case INIT_CONTEXT_IA_SUCCESS:
      return {
        ...state,
        userSession: action.payload,
        error: null,
      };
    case INIT_CONTEXT_IA_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case GET_RESPONSE_SUCCESS:
      return {
        ...state,
        messages: [...state.messages, action.payload],
        error: null,
      };
    case GET_RESPONSE_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case USER_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    default:
      return state;
  }
};

export default iaReducer;
