import { api } from "../../config/axios";
import {
  INIT_CONTEXT_IA_SUCCESS,
  INIT_CONTEXT_IA_FAILURE,
  GET_RESPONSE_SUCCESS,
  GET_RESPONSE_FAILURE,
  USER_MESSAGE,
  SET_LOADING,
} from "./types";

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
});

export const initContextIA = (config) => async (dispatch) => {
  try {
    const response = await api.post("/default-init-context-ia", config);
    dispatch({ type: INIT_CONTEXT_IA_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({
      type: INIT_CONTEXT_IA_FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
};

export const getResponse = (message, userSession) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const response = await api.post("/getResponse", { message, userSession });
    console.log("Response from API:", response.data); // Log to verify the response
    dispatch({
      type: GET_RESPONSE_SUCCESS,
      payload: {
        sender: "ai",
        text: response.data.output,
        form: response.data.form ? response.data.form : null, // Include form if it exists
      },
    });
  } catch (error) {
    dispatch({
      type: GET_RESPONSE_FAILURE,
      payload: error.response ? error.response.data : error.message,
    });
  }
  dispatch(setLoading(false));
};

export const sendMessage = (message, userSession) => async (dispatch) => {
  dispatch({ type: USER_MESSAGE, payload: { sender: "user", text: message } });
  await dispatch(getResponse(message, userSession));
};
